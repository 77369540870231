import { createContext, useState } from 'react';
import { IntlProvider } from 'react-intl';
import usMessages from '../lang/en-US.json';
import esMessages from '../lang/es-MX.json';

import { useTranslation } from 'react-i18next';

const langContext = createContext();

const LangProvider = ({ children }) => {
  let defaulLocale;
  let defaultMessages;
  let defaulLocaleChange;
  let defaulStore;

  const browserLang = navigator.language || navigator.userLanguage;

  const lang = localStorage.getItem('lang') || 'es-MX';

  if (lang) {
    defaulStore = browserLang.includes('es');
    defaulLocale = lang;
    if (lang === 'es-MX') {
      defaultMessages = esMessages;
      defaulLocaleChange = 'en-US';
    } else if (lang === 'en-US') {
      defaultMessages = usMessages;
      defaulLocaleChange = 'es-MX';
    }
  } else {
    defaulStore = browserLang.includes('es');
    if (browserLang.includes('es')) {
      defaulLocale = 'es-MX';
      defaultMessages = esMessages;
      defaulLocaleChange = 'en-US';
      localStorage.setItem('lang', 'es-MX');
    } else {
      defaulLocale = 'en-US';
      defaultMessages = usMessages;
      defaulLocaleChange = 'es-MX';
      localStorage.setItem('lang', 'en-US');
    }
  }

  const [messages, setMessages] = useState(defaultMessages);
  const [locale, setLocale] = useState(defaulLocale);
  const [changeLang, setChangeLang] = useState(defaulLocaleChange);
  const { t, i18n } = useTranslation();

  const setLang = (language) => {
    i18n.changeLanguage(language);

    switch (language) {
      case 'es-MX':
        setMessages(esMessages);
        setLocale('es-MX');
        setChangeLang('en-US');
        localStorage.setItem('lang', 'es-MX');
        break;
      case 'en-US':
        setMessages(usMessages);
        setLocale('en-US');
        setChangeLang('es-MX');
        localStorage.setItem('lang', 'en-US');
        break;
      default:
        setMessages(usMessages);
        setLocale('en-US');
        setChangeLang('es-MX');
        localStorage.setItem('lang', 'en-US');
        break;
    }
  };

  const datos = {
    setLang,
    changeLang,
    locale,
    defaulStore,
    t,
    lang: i18n.language,
  };

  return (
    <langContext.Provider value={datos}>
      <IntlProvider locale={locale} messages={messages}>
        {children}
      </IntlProvider>
    </langContext.Provider>
  );
};

export { LangProvider, langContext };
