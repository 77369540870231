export const useCardProduct = (prod) => {
  const {
    colors,
    rutas,
    _id,
    category,
    type,
    modelo,
    subCategory,
    nameCatego,
  } = prod;
  const product = type;
  const mainImg =
    category === 'strings'
      ? rutas[0]
      : modelo === 'Blade'
      ? colors[1].rutas[0]
      : colors[0].rutas[0];
  const imgSize = category === 'strings' ? 'contain' : 'cover';
  const imgHeight = category === 'strings' ? '250px' : '450px';
  return {
    category,
    product,
    _id,
    mainImg,
    imgSize,
    imgHeight,
    modelo,
    subCategory,
    nameCatego,
  };
};
