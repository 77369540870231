import React from 'react';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';
import ReactDOM from 'react-dom/client';
import { HashRouter } from 'react-router-dom';
import App from './App';
import { LoginProvider } from './context/loginContext';

// i18n librería para traducción
import { I18nextProvider } from 'react-i18next';
import i18n from './utils/i18n';

if (process.env.NODE_ENV === 'production') {
  disableReactDevTools();
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HashRouter>
    <I18nextProvider i18n={i18n}>
      <LoginProvider>
        <App />
      </LoginProvider>
    </I18nextProvider>
  </HashRouter>
);
