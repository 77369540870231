import styles from './VibesHero.module.css';

const VibesHero = () => {
  return (
    <div className={styles.heroSlideContainer}>
      <div className={styles.heroImg}>
        <img
          src="https://res.cloudinary.com/de17sq7ft/image/upload/v1726269346/hero-vibesc_gqdn2l.jpg"
          alt="vibes hero"
        />
      </div>
      <img
        className={styles.heroFirma}
        src="https://res.cloudinary.com/de17sq7ft/image/upload/v1726269583/placa-babilonc_ghqtek.png"
        alt="firma babilon"
      />
      <img
        className={styles.heroText}
        src="https://res.cloudinary.com/de17sq7ft/image/upload/v1726269581/texto-vibesc_rkrown.png"
        alt="texto"
      />
    </div>
  );
};

export default VibesHero;
