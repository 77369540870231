import React from 'react';
import './about.css';
import { FormattedMessage } from 'react-intl';

const About = () => {
  return (
    <section className="section__about">
      <h3
        className="about__title"
        style={{ fontSize: '4rem', marginBottom: '15px' }}
      >
        <FormattedMessage id="acerca.title" defaultMessage="About Babilon" />
      </h3>
      <div
        className="title__left-line"
        style={{ margin: 'auto', marginBottom: '50px' }}
      ></div>
      <div className="container__about">
        <div className="about__image">
          <img
            src="https://i.ibb.co/Bz3Kkjm/disenoc.jpg"
            alt="babilon artist"
          />
        </div>
        <div className="about__top">
          <p className="top__text texto">
            <FormattedMessage id="acerca.history" defaultMessage="history" />
          </p>
          <img
            className="top__img"
            src="https://i.ibb.co/Tcytn8Y/img-about-0-min.png"
            alt="artist babilon"
          />
        </div>
      </div>
    </section>
  );
};

export default About;
