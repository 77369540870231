import React from 'react';
import { Helmet } from 'react-helmet';
// import Contact from "../../components/main/contact/Contact";
import Hero from '../../components/main/hero/Hero';
import NewSeries from '../../components/main/newSeries/NewSeries';
import TopProducts from '../../components/main/topProducts/TopProducts';
import SectionEvents from '../../components/main/sectionEvents/SectionEvents';
// import Promo from "../../components/main/promo/Promo";

//Importacion componentes

const HomePage = () => {
  return (
    <section>
      <Helmet>
        <title>Babilon - Home</title>
      </Helmet>
      <Hero />
      {/* <Promo /> */}
      <TopProducts />
      <NewSeries />
      <TopProducts type="strings" />
      {/* <Contact /> */}
      <SectionEvents />
    </section>
  );
};

export default HomePage;
