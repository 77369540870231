import React from "react";
import IMG from "../../assets/imgs/404P.svg";
import { BiArrowBack } from "react-icons/bi";
import { Link } from "react-router-dom";

import "./error.css";

const Error = () => {
  return (
    <section className="error404__container">
      <img src={IMG} alt="error en carga de pagina" className="error404__img" />
      <span className="error404__text">Something is wrong</span>
      <Link to="/" className="error404__btn">
        <BiArrowBack />
        Babilon Home
      </Link>
    </section>
  );
};

export default Error;
