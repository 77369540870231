import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getProductsByCategoryAndProduct } from '../services/products';

export const useStringsCategory = () => {
  const { category, product } = useParams();
  const [strings, setStrings] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(true);
    getProductsByCategoryAndProduct(category, product).then((data) => {
      setStrings(data);
      setInterval(() => {
        setLoader(false);
      }, 1500);
    });
  }, [product, category]);

  // const Basics = strings.filter((string) => string.gama === 'Basic');

  let Professionals = [];
  let Masters = [];
  let Premiums = [];

  const guitarStrings = {};
  const bassStrings = {};
  const ukuleleStrings = {};

  if (product) {
    Professionals = strings
      .filter(
        (string) =>
          string.gama === 'Professional Tour' && !string.modelo.includes('PACK')
      )
      .sort((a, b) => a.modelo.localeCompare(b.modelo));

    Masters = strings
      .filter((string) => string.gama === 'Master Tour')
      .sort((a, b) => a.modelo.localeCompare(b.modelo));

    Premiums = strings
      .filter((string) => string.gama === 'Premium Tour')
      .sort((a, b) => a.modelo.localeCompare(b.modelo));
  } else {
    //
    //
    // guitarra
    guitarStrings.professionals = strings
      .filter(
        ({ type, gama, modelo }) =>
          type === 'guitar' &&
          gama === 'Professional Tour' &&
          !modelo.includes('PACK')
      )
      .sort((a, b) => a.modelo.localeCompare(b.modelo));
    guitarStrings.masters = strings
      .filter(({ type, gama }) => type === 'guitar' && gama === 'Master Tour')
      .sort((a, b) => a.modelo.localeCompare(b.modelo));
    guitarStrings.premiums = strings
      .filter(({ type, gama }) => type === 'guitar' && gama === 'Premium Tour')
      .sort((a, b) => a.modelo.localeCompare(b.modelo));

    // bajo
    bassStrings.professionals = strings
      .filter(
        ({ type, gama }) => type === 'bass' && gama === 'Professional Tour'
      )
      .sort((a, b) => a.modelo.localeCompare(b.modelo));
    bassStrings.masters = strings
      .filter(({ type, gama }) => type === 'bass' && gama === 'Master Tour')
      .sort((a, b) => a.modelo.localeCompare(b.modelo));
    bassStrings.premiums = strings
      .filter(({ type, gama }) => type === 'bass' && gama === 'Premium Tour')
      .sort((a, b) => a.modelo.localeCompare(b.modelo));

    // ukulele
    ukuleleStrings.professionals = strings
      .filter(
        ({ type, gama }) => type === 'ukulele' && gama === 'Professional Tour'
      )
      .sort((a, b) => a.modelo.localeCompare(b.modelo));
    ukuleleStrings.masters = strings
      .filter(({ type, gama }) => type === 'ukulele' && gama === 'Master Tour')
      .sort((a, b) => a.modelo.localeCompare(b.modelo));
    ukuleleStrings.premiums = strings
      .filter(({ type, gama }) => type === 'ukulele' && gama === 'Premium Tour')
      .sort((a, b) => a.modelo.localeCompare(b.modelo));
  }

  return {
    // Basics,
    Professionals,
    Masters,
    Premiums,
    loader,
    strings: {
      guitarStrings,
      bassStrings,
      ukuleleStrings,
    },
  };
};
