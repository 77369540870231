import { useEffect, useState } from 'react';
import StringsGamas from '../StringsGamas/StringsGamas';

import styles from './AllStrings.module.css';

const AllStrings = ({ strings, lang }) => {
  const { guitarStrings, bassStrings, ukuleleStrings } = strings;
  const [title, setTitle] = useState();

  useEffect(() => {
    setTitle(() => {
      if (lang === 'es-MX') {
        return {
          guitar: 'Guitarra',
          bass: 'Bajo',
        };
      }
      return {
        guitar: 'Guitar',
        bass: 'Bass',
      };
    });
  }, [lang]);

  // className="title__left title--category"

  return (
    <section className={styles.sectionContainer}>
      <article>
        <div className="title__left title--category">
          <span>{title?.guitar}</span>
        </div>
        <StringsGamas gamas={guitarStrings} />
      </article>

      <article>
        <div className="title__left title--category">
          <span>{title?.bass}</span>
        </div>
        <StringsGamas gamas={bassStrings} />
      </article>

      <article>
        <div className="title__left title--category">
          <span>Ukulele</span>
        </div>
        <StringsGamas gamas={ukuleleStrings} />
      </article>
    </section>
  );
};

export default AllStrings;
