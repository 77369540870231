import React, { useEffect, useState } from "react";
import SerieCard from "../serieCard/SerieCard";
import { getSeries } from "../../../services/series";

import "./newSeries.css";

const NewSeries = () => {
  const [series, setSeries] = useState([]);

  useEffect(() => {
    getSeries().then((data) => setSeries(data));
  }, []);

  return (
    <section className="section__newSeries">
      {series.map((el) => (
        <SerieCard serieInfo={el} key={el._id} />
      ))}
    </section>
  );
};

export default NewSeries;
