import React, { useEffect, useState } from "react";
import EventCard from "../eventCard/EventCard";
import "./eventsAndNews.css";
import { FormattedMessage } from "react-intl";
import { Helmet } from "react-helmet";
import SkeletonEventCards from "../../skeletons/SkeletonEventCards";
import { getEvents } from "../../../services/events";

const EventsAndNews = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getEvents().then((data) => {
      setEvents(data);
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    });
  }, []);

  return (
    <div className="EandN">
      <Helmet>
        <title>Events & News</title>
      </Helmet>
      <h2 className="EandN__title">
        <FormattedMessage id="event&news" defaultMessage="Events & News" />
      </h2>
      {loading ? (
        <SkeletonEventCards />
      ) : (
        <div className="EandN__list">
          {events.map((el) => (
            <EventCard key={el._id} infoEvent={el} />
          ))}
        </div>
      )}
    </div>
  );
};

export default EventsAndNews;
