import React from 'react';
// import { BsImage } from "react-icons/bs";
import Skeleton from './Skeleton';
import LoaderLogoBabilon from '../products/loaders/LoaderLogoBabilon/LoaderLogoBabilon';

const SkeletonImages = () => {
  return (
    <div className="productPage__images">
      <Skeleton classes="imgProduct">
        {/* <BsImage className="skeleton__iconImage" /> */}
        <LoaderLogoBabilon />
      </Skeleton>
      <div className="skeleton__prodcut-Images">
        <Skeleton classes="imgThum">
          {/* <BsImage className="skeleton__iconImage-thum" /> */}
          <LoaderLogoBabilon />
        </Skeleton>
        <Skeleton classes="imgThum">
          {/* <BsImage className="skeleton__iconImage-thum" /> */}
          <LoaderLogoBabilon />
        </Skeleton>
        <Skeleton classes="imgThum">
          {/* <BsImage className="skeleton__iconImage-thum" /> */}
          <LoaderLogoBabilon />
        </Skeleton>
      </div>
    </div>
  );
};

export default SkeletonImages;
