import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "../components/main/footer/Footer";
import Header from "../components/main/header/Header";
import { HeaderProvider } from "../context/headerContext";

const HomeLayout = () => {
  return (
    <section>
      <HeaderProvider>
        <Header />
      </HeaderProvider>
      <Outlet />
      <Footer />
    </section>
  );
};

export default HomeLayout;
