import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Product from '../../components/products/product/Product';
import SkeletonProduct from '../../components/skeletons/SkeletonProduct';
import { getProduct } from '../../services/products';

const ProductPage = () => {
  const { item } = useParams();

  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getProduct(item).then((data) => {
      setProduct(data);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    });
  }, [item]);

  if (product === null) return <div></div>;

  return loading ? <SkeletonProduct /> : <Product productData={product} />;
};

export default ProductPage;
