import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getProductsBySerie } from '../../../services/products';
import CardProduct from '../cardProduct/CardProduct';
import DinamicHero from '../dinamicHero/DinamicHero';
import SkeletonProductCards from '../../skeletons/SkeletonProductCards';
import './series.css';
import { langContext } from '../../../context/langContext';

const Series = () => {
  const { serie, category } = useParams();
  const { locale } = useContext(langContext);
  const [series, setSeries] = useState([]);
  const [guitars, setGuitars] = useState([]);
  const [basses, setBasses] = useState([]);
  const [ukulele, setUkulele] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(true);
    getProductsBySerie(serie, category).then((data) => {
      setGuitars(
        data
          .filter((el) => el.isSubModelo !== true)
          .reverse()
          .filter((el) => el.category === 'guitars')
          .filter((el) => el.modelo !== 'Thunder')
      );
      setBasses(
        data
          .filter((el) => el.isSubModelo !== true)
          .reverse()
          .filter((el) => el.category === 'bass')
      );
      setUkulele(
        data
          .filter((el) => el.isSubModelo !== true)
          .reverse()
          .filter((el) => el.category === 'ukuleles')
      );
      if (serie === 'Unique') {
        setSeries(data.filter((el) => el.isSubModelo !== true).reverse());
      } else if (category === 'Guitars') {
        const firstData = data.filter((el) => el.isSubModelo !== true);
        const itemThender = firstData.filter((el) => el.modelo !== 'Thunder');
        // const itemShredder = itemThender.filter((el) => el.modelo !== 'Zeluz');
        // const realData = itemShredder;
        // setSeries(data.filter((el) => el.isSubModelo !== true));
        // setSeries([...realData, itemThender[2]]);
        const realData = itemThender;
        setSeries(realData);
      } else {
        setSeries(data.filter((el) => el.isSubModelo !== true));
      }
      setTimeout(() => {
        setLoader(false);
      }, 1500);
    });
  }, [serie, category]);

  return (
    <section>
      <DinamicHero />
      <div className="category__container">
        <div className="title__left title--category">
          <span>
            {locale === 'en-US'
              ? category === 'Bass'
                ? 'Basses'
                : category
              : category === 'Bass'
              ? 'Bajos'
              : category === 'Guitars'
              ? 'Guitarras'
              : category}
          </span>
        </div>
        {loader ? (
          <SkeletonProductCards />
        ) : category === undefined ? (
          <div>
            <div>
              <h2 className="strings__Title">GUITARS</h2>
              <div className="categoryStrings__list">
                <div className="categoryStrings__list-ft">
                  {guitars.map((el, index) => (
                    <CardProduct key={index} prod={el} />
                  ))}
                </div>
              </div>
            </div>
            <div>
              <h2 className="strings__Title">BASSES</h2>
              <div className="categoryStrings__list">
                <div className="categoryStrings__list-ft">
                  {basses.map((el, index) => (
                    <CardProduct key={index} prod={el} />
                  ))}
                </div>
              </div>
            </div>
            <div>
              <h2 className="strings__Title">UKULELES</h2>
              <div className="categoryStrings__list">
                <div className="categoryStrings__list-ft">
                  {ukulele.map((el, index) => (
                    <CardProduct key={index} prod={el} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="series__list">
            <div className="series__list-ft">
              {series.map((el) => (
                <CardProduct prod={el} key={el._id} />
              ))}
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default Series;
