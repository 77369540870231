import { useContext, useState } from "react";
import { langContext } from "../context/langContext";

export const useDropdown = (info) => {
  const { locale } = useContext(langContext);
  const { labelUs, labelEs, items, content } = info;
  const [showDrop, setshowDrop] = useState(false);

  const currentLabel = locale === "en-US" ? labelUs : labelEs;

  const handleDropdown = (newStateDrop) => {
    setshowDrop(newStateDrop);
  };
  return {
    showDrop,
    handleDropdown,
    currentLabel,
    items,
    labelUs,
    content,
  };
};
