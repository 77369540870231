import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { langContext } from '../../../context/langContext';
import { FaRegCalendarAlt } from 'react-icons/fa';

import './eventCard.css';

const EventCard = ({ infoEvent }) => {
  const { locale } = useContext(langContext);
  const { usLang, esLang, imgCard, date, tag } = infoEvent;
  const lang = locale === 'en-US' ? usLang : esLang;
  return (
    <Link to={`/events-and-news/${tag}`} className="card__event">
      <img
        loading="lazy"
        className="event__img"
        src={imgCard}
        alt="nuevas guitarras babilon"
      />
      <div className="event__info">
        <span className="info__date">
          <FaRegCalendarAlt className="date__calendar" />
          {date}
        </span>
        <h3 className="info__title">{lang.title}</h3>
        <p className="info__text">{lang.smallInfo}</p>
      </div>
    </Link>
  );
};

export default EventCard;
