import axios from './axios';

export const getEvents = () => {
  const url = 'events/';
  return axios.get(url).then((res) => res.data);
};

export const getEvent = (event) => {
  // const url = `events/${event}`;
  const url = `events?tag=${event}`;
  return axios.get(url).then((res) => res.data[0]);
};
