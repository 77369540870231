// librerías
import { Link } from 'react-router-dom';

// rutas
import PATH_ROUTES from '../../../../constants/pathRoutes';

// estilos
import styles from './ArturoHero.module.css';

const ArturoHero = () => {
  return (
    <Link to={PATH_ROUTES.ROSTROS_89}>
      <div className={styles.heroSlideContainer}>
        <div className={styles.heroImg}>
          <img
            src="https://res.cloudinary.com/de17sq7ft/image/upload/v1729266485/arturo-ybarra-bg_vfvhus.jpg"
            alt="fondo Arturo Ybarra"
          />
        </div>

        <img
          className={styles.heroIllustration}
          src="https://res.cloudinary.com/de17sq7ft/image/upload/v1729266485/Ilustracio%CC%81n_vyakpt.png"
          alt="ilustración"
        />
        <div className={styles.heroLogo}>
          <img
            src="https://res.cloudinary.com/de17sq7ft/image/upload/v1732735612/logo_qscfr0.png"
            alt="logo rostros 89"
          />
        </div>

        <span className={styles.heroName}>Arturo Ybarra</span>
        <div className={styles.heroTag}>
          <span>Edición exclusiva no disponible a la venta</span>
        </div>
      </div>
    </Link>
  );
};

export default ArturoHero;
