import { useEffect, useState } from "react";

export const useSlide = (data) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [width, setWidth] = useState(window.innerWidth);
  const numProducts = data.length;

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => {
    setWidth(window.innerWidth);
  };

  const nextSlide = () => {
    if (width > 680 && width < 980) {
      setCurrentSlide(
        currentSlide === numProducts - 2 ? 0 : (prev) => prev + 1
      );
    } else if (width > 980 && width < 1280) {
      setCurrentSlide(
        currentSlide === numProducts - 2 ? 0 : (prev) => prev + 1
      );
    } else if (width > 1280) {
      setCurrentSlide(
        currentSlide === numProducts - 4 ? 0 : (prev) => prev + 1
      );
    } else {
      setCurrentSlide(
        currentSlide === numProducts - 1 ? 0 : (prev) => prev + 1
      );
    }
  };

  const prevSlide = () => {
    if (width > 680 && width < 980) {
      setCurrentSlide(
        currentSlide === 0 ? numProducts - 2 : (prev) => prev - 1
      );
    } else if (width > 980 && width < 1280) {
      setCurrentSlide(
        currentSlide === 0 ? numProducts - 2 : (prev) => prev - 1
      );
    } else if (width > 1280) {
      setCurrentSlide(
        currentSlide === 0 ? numProducts - 4 : (prev) => prev - 1
      );
    } else {
      setCurrentSlide(
        currentSlide === 0 ? numProducts - 1 : (prev) => prev - 1
      );
    }
  };
  return { currentSlide, nextSlide, prevSlide };
};
