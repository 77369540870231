import React from 'react';
import { BsFacebook } from 'react-icons/bs';
import { AiFillInstagram } from 'react-icons/ai';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import POLITICS from '../../../assets/files/PP-babilon.pdf';
import TERMS from '../../../assets/files/TU-babilon.pdf';

import './footer.css';

const Footer = () => {
  return (
    <footer className="section__footer">
      <div className="top__footer">
        <img
          className="footer__logo"
          src="https://i.ibb.co/VYxn6CC/babilon-morado-simple-new.png"
          alt="logotipo babilon blanco"
        />
        <div className="footer__colums">
          <div className="colums__colum">
            <span className="colum__title">
              <FormattedMessage
                id="footer.products"
                defaultMessage="Products"
              />
            </span>
            <Link to="/products/Guitars" className="colum__item">
              <FormattedMessage
                id="footer.products-item1"
                defaultMessage="Guitars"
              />
            </Link>
            <Link to="/products/Bass" className="colum__item">
              <FormattedMessage
                id="footer.products-item2"
                defaultMessage="Bass"
              />
            </Link>
            <Link to="/products/Ukuleles" className="colum__item">
              <FormattedMessage
                id="footer.products-item3"
                defaultMessage="Ukuleles"
              />
            </Link>
            <Link to="/products/Strings/Guitar" className="colum__item">
              <FormattedMessage
                id="footer.products-item4"
                defaultMessage="Strings"
              />
            </Link>
          </div>
          <div className="colums__colum">
            <span className="colum__title">
              <FormattedMessage
                id="footer.contactTag"
                defaultMessage="Contact"
              />
            </span>
            <span className="colum__item">
              Naranjo 76, Sta María la Ribera, Cuauhtémoc, 06400 Ciudad de
              México, CDMX
            </span>
          </div>
          <div className="colums__colum">
            <span className="colum__title">
              <FormattedMessage
                id="footer.politicsTag"
                defaultMessage="Politics"
              />
            </span>
            <a href={POLITICS} download className="colum__item">
              <FormattedMessage
                id="footer.privacy"
                defaultMessage="Privacy Policy"
              />
            </a>
            <a href={TERMS} download className="colum__item">
              <FormattedMessage
                id="footer.terms"
                defaultMessage="Terms of service"
              />
            </a>
          </div>
          <div className="colums__colum">
            <span className="colum__title">
              <FormattedMessage id="footer.socials" defaultMessage="Socials" />
            </span>
            <div className="colum__socials">
              <a
                href="https://www.facebook.com/BabilonGuitars/"
                target="blank"
                className="socials__item"
              >
                <BsFacebook />
              </a>
              <a
                href="https://www.instagram.com/babilonguitars"
                target="blank"
                className="socials__item"
              >
                <AiFillInstagram />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="footer__down">
        <span>© Copyright Babilon Guitars and Basses, 2024</span>
      </div>
      <div className="footer__img">
        <img
          src="https://i.ibb.co/pKvyDQy/footer.png"
          alt="imagen de pie de pagina"
        />
      </div>
      <div className="footer__bg">
        <div className="footer__opacity-efect-top"></div>
        <img
          className="bg__img"
          src="https://i.ibb.co/K7NrQkZ/bg-footer.jpg"
          alt="imagen de fondo de pie de pagina"
        />
      </div>
    </footer>
  );
};

export default Footer;
