import { useState } from 'react';

const usePopup = () => {
  const [openPopup, setOpenPopup] = useState(false);
  const [popupContent, setPopupContent] = useState({});

  const handleOpenPopup = (event, payload) => {
    setPopupContent((state) => {
      const prop = event.target.name;
      const value = !state[prop];
      const url = payload;

      return {
        [prop]: value,
        url,
      };
    });

    setOpenPopup((value) => !value);
  };

  return {
    openPopup,
    popupContent,
    handleOpenPopup,
  };
};

export default usePopup;
