import React from 'react';

// estilos
import './loaderLogoBabilon.css';

const LoaderLogoBabilon = () => {
  return (
    <div className="loader__logo">
      <img
        className="logo__img"
        src="https://i.ibb.co/XxkK259/Babilon-sin-fondo.png"
        alt="b de babilon"
      />
    </div>
  );
};

export default LoaderLogoBabilon;
