import React from 'react';
import Skeleton from './Skeleton';
import LoaderLogoBabilon from '../products/loaders/LoaderLogoBabilon/LoaderLogoBabilon';
// import { BsImage } from "react-icons/bs";

const SkeletonProductCards = () => {
  let products = [0, 0, 0, 0, 0, 0, 0];
  return (
    <div className="category__list">
      <div className="category__list-ft">
        {products.map((el, i) => (
          <div className="cardProduct" key={i}>
            <Skeleton classes="imgProduct">
              {/* <BsImage className="skeleton__iconImage" /> */}
              <LoaderLogoBabilon />
            </Skeleton>
            <Skeleton classes="text width-25" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SkeletonProductCards;
