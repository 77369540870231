import { useState, useEffect } from 'react';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import './sliderThumbs.css';

const SliderThumbs = ({ data, handleMainIMG }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [width, setWidth] = useState(window.innerWidth);
  const numProducts = data.length;

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleResize = () => {
    setWidth(window.innerWidth);
  };

  const nextSlide = () => {
    if (width > 680 && width < 980) {
      setCurrentSlide(
        currentSlide === numProducts - 4 ? 0 : (prev) => prev + 1
      );
    } else if (width > 980 && width < 1280) {
      setCurrentSlide(
        currentSlide === numProducts - 4 ? 0 : (prev) => prev + 1
      );
    } else if (width > 1280) {
      setCurrentSlide(
        currentSlide === numProducts - 4 ? 0 : (prev) => prev + 1
      );
    } else {
      setCurrentSlide(
        currentSlide === numProducts - 3 ? 0 : (prev) => prev + 1
      );
    }
  };

  const prevSlide = () => {
    if (width > 680 && width < 980) {
      setCurrentSlide(
        currentSlide === 0 ? numProducts - 4 : (prev) => prev - 1
      );
    } else if (width > 980 && width < 1280) {
      setCurrentSlide(
        currentSlide === 0 ? numProducts - 4 : (prev) => prev - 1
      );
    } else if (width > 1280) {
      setCurrentSlide(
        currentSlide === 0 ? numProducts - 4 : (prev) => prev - 1
      );
    } else {
      setCurrentSlide(
        currentSlide === 0 ? numProducts - 3 : (prev) => prev - 1
      );
    }
  };

  return (
    <div className="sliderThumbs">
      <div className="sliderThumbs__container">
        <div
          className="sliderThumbs__list"
          style={{
            transform: `translateX(-${
              currentSlide * (width < 600 ? 90 : 120)
            }px)`,
          }}
        >
          {data.map((el, index) => (
            <div className="productPage__thumbs-item">
              <img
                src={el}
                alt="producto babilon"
                key={index}
                onClick={() => handleMainIMG(index)}
              />
            </div>
          ))}
        </div>
        <div
          className="sliderThumbs__arrow-back sliderThumbs__arrow"
          onClick={prevSlide}
        >
          <IoIosArrowBack />
        </div>
        <div
          className="sliderThumbs__arrow-next sliderThumbs__arrow"
          onClick={nextSlide}
        >
          <IoIosArrowForward />
        </div>
      </div>
    </div>
  );
};

export default SliderThumbs;
