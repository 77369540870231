// import VIDEO from "../../../assets/video/loop vintage website_1.webm";
// import VIDEOMP4 from "../../../assets/video/loop_home.mp4";
// import IMG from "../../../assets/imgs/Babilon editable-min.png";
// import IMG2 from "../../../assets/imgs/mainHero.jpeg";

// componentes
import VibesHero from './VibesHero/VibesHero';
import ArturoHero from './ArturoHero/ArturoHero';

// librerías
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';

// hooks
// import useWindowSize from "../../../hooks/useWindowSize";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// styles
import './hero.css';

// import required modules
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import MelissaHero from './MelissaHero/MelissaHero';

const Hero = () => {
  // const { width } = useWindowSize();

  return (
    <section className="hero">
      <Swiper
        slidesPerView={1}
        loop={true}
        pagination={{ clickable: true }}
        modules={[Autoplay, Pagination]}
        className="hero__slider"
        autoplay={{
          delay: 6000,
          disableOnInteraction: false,
        }}
        spaceBetween={30}
      >
        <SwiperSlide>
          <VibesHero />
        </SwiperSlide>
        <SwiperSlide>
          <MelissaHero />
        </SwiperSlide>
        <SwiperSlide>
          <ArturoHero />
        </SwiperSlide>

        {/* <SwiperSlide className="slider__item">
          <div className="item__info">
            <div
              className="info__container"
              style={{
                justifyContent: 'flex-start',
              }}
            >
              <div
                className="info__c"
                style={{ color: 'var(--white)', alignItems: 'flex-start' }}
              >
                <h3 className="hero__title">MASTER BASS IV</h3>
                <img
                  className="info__logo"
                  src="https://i.ibb.co/BfL26yz/unique-bg.png"
                  alt="logo babilon"
                />
                <span className="hero__subTitle">
                  Vibrate with your universe
                </span>
                <Link
                  to="Series/Unique/Bass"
                  className="info__btn"
                  style={{
                    marginLeft: '10px',
                  }}
                >
                  <FormattedMessage
                    id="hero.learn"
                    defaultMessage="LEARN MORE"
                  />
                </Link>
              </div>
              <span className="hero__name position-left-to-right">
                Karla Molkovich
              </span>
            </div>
          </div>
          <img
            src="https://res.cloudinary.com/de17sq7ft/image/upload/v1726862435/MASTER-HERO_tiloan.jpg"
            alt="babilon guitars hero"
            className="hero__img"
            style={{ objectPosition: '53% center' }}
          />
        </SwiperSlide> */}
        <SwiperSlide className="slider__item">
          <div className="item__info">
            <div
              className="info__container"
              style={{
                justifyContent: 'flex-start',
              }}
            >
              <div
                className="info__c"
                style={{ color: 'var(--white)', alignItems: 'flex-start' }}
              >
                <span className="hero__leabel-new-mbv ">
                  <FormattedMessage id="hero.new" defaultMessage="New!" />
                </span>
                <h3 className="hero__title">MASTER BASS V</h3>
                <img
                  className="info__logo"
                  src="https://i.ibb.co/BfL26yz/unique-bg.png"
                  alt="logo babilon"
                />
                {/* <span className="hero__subTitle">
                  Vibrate with your universe
                </span> */}
                <Link
                  to="Series/Unique/Bass"
                  className="info__btn"
                  style={{
                    marginLeft: '10px',
                  }}
                >
                  <FormattedMessage
                    id="hero.learn"
                    defaultMessage="LEARN MORE"
                  />
                </Link>
              </div>
              <span className="hero__name position-left-to-right">
                Sabo Romo
              </span>
            </div>
          </div>
          <img
            src="https://res.cloudinary.com/de17sq7ft/image/upload/v1726862432/banner-sabo-romo_jrgb7s.jpg"
            alt="babilon guitars hero"
            className="hero__img"
            style={{ objectPosition: '81% 10%' }}
          />
        </SwiperSlide>
        {/* <SwiperSlide className="slider__item">
          <div className="item__info">
            <div className="info__container">
              <div
                className="info__c"
                style={{ color: 'var(--white)', alignItems: 'flex-end' }}
              >
                <h3 className="hero__title">LEGGEND</h3>
                <img
                  className="info__logo"
                  src="https://i.ibb.co/BfL26yz/unique-bg.png"
                  alt="logo babilon"
                />
                <Link
                  to="products/guitars/electric/65d636a32d55000012ff8ee5"
                  className="info__btn"
                >
                  <FormattedMessage
                    id="hero.learn"
                    defaultMessage="LEARN MORE"
                  />
                </Link>
              </div>
              <span className="hero__name position-right-to-left">
                Nino Oxilia
              </span>
            </div>
          </div>
          <img
            src="https://i.ibb.co/DptHxnh/hero-realc.jpg"
            alt="babilon guitars hero"
            className="hero__img"
            style={{ objectPosition: '20% 30%' }}
          />
        </SwiperSlide> */}
        <SwiperSlide className="slider__item">
          <div className="item__info">
            <div
              className="info__container"
              style={{
                justifyContent: 'flex-start',
              }}
            >
              <div
                className="info__c"
                style={{
                  color: 'var(--white)',
                  alignItems: 'flex-start',
                }}
              >
                <span className="hero__leabel-new-bs">
                  <FormattedMessage id="hero.new" defaultMessage="New!" />
                </span>
                <h3 className="hero__title">B-SERIES</h3>
                <img
                  className="info__logo"
                  src="https://i.ibb.co/zmSqsnd/evolution-white.png"
                  alt="logo babilon"
                  style={{ marginBottom: '10px' }}
                />
                <Link to="Series/B-Series/Guitars" className="info__btn">
                  <FormattedMessage
                    id="hero.learn"
                    defaultMessage="LEARN MORE"
                  />
                </Link>
              </div>
              <span className="hero__name position-left-to-right">Rani</span>
            </div>
          </div>
          <img
            src="https://res.cloudinary.com/de17sq7ft/image/upload/v1726862430/rani-hero_fddlq8.jpg"
            alt="babilon guitars hero"
            className="hero__img"
            style={{ objectPosition: '80% 0' }}
          />
        </SwiperSlide>
        {/* <SwiperSlide className="slider__item">
          <div className="item__info">
            <div
              className="info__container"
              style={{
                justifyContent: "flex-start",
              }}
            >
              <div className="info__c" style={{ color: "var(--white)", alignItems: "flex-start" }}>
                <h3 className="hero__title">COSMOS II</h3>
                <img
                  className="info__logo"
                  src="https://i.ibb.co/BfL26yz/unique-bg.png"
                  alt="logo babilon"
                />
                <span className="hero__subTitle">Classy(c) Sound</span>
                <Link to="products/guitars/electric/65d63651d9d0d0001254a144" className="info__btn">
                  <FormattedMessage id="hero.learn" defaultMessage="LEARN MORE" />
                </Link>
              </div>
            </div>
          </div>
          <img
            src={IMG}
            alt="babilon guitars hero"
            className="hero__img"
            style={{ objectPosition: "77% 30%" }}
          />
        </SwiperSlide> */}
        {/* <SwiperSlide className="slider__item">
          <div className="item__info">
            <div
              className="info__container"
              style={{
                justifyContent: "flex-start",
              }}
            >
              <div
                className="info__c"
                style={{ color: "var(--white)", alignItems: "flex-start" }}
              >
                <h3 className="hero__title">COSMOS</h3>
                <img
                  className="info__logo"
                  src="https://i.ibb.co/BfL26yz/unique-bg.png"
                  alt="logo babilon"
                />
                <span className="hero__subTitle">
                  Vibrate with your universe
                </span>
                <Link
                  to="products/guitars/electric/65d6367cd9d0d0001254a145"
                  className="info__btn"
                >
                  <FormattedMessage
                    id="hero.learn"
                    defaultMessage="LEARN MORE"
                  />
                </Link>
              </div>
            </div>
          </div>
          <img
            src="https://i.ibb.co/JRYyrwM/cosmos-hero-min.jpg"
            alt="babilon guitars hero"
            className="hero__img"
          />
        </SwiperSlide> */}
      </Swiper>
      {/* <video muted autoPlay loop className="hero__video">
        <source src={VIDEO} type="video/webm" />
        <source src={VIDEOMP4} type="video/mp4" />
      </video>
      <div className="hero__shadow">
        <div className="hero__content">
          <div className="hero__content-wrapper">
            <h2 className="hero__slogan-serie">
              <FormattedMessage
                id="header.uniqueLabel"
                defaultMessage="Meet the new line"
              />
            </h2>
            <img
              className="hero__logo-serie"
              src="https://i.ibb.co/BfL26yz/unique-bg.png"
              alt="serie unique babilon"
            />
            <h2 className="hero__slogan">
              <FormattedMessage
                id="header.uniqueSlogan"
                defaultMessage="¡Vibrate with your universe!"
              />
            </h2>

            <img
              className="hero__logoB"
              src="https://i.ibb.co/MDdv5jy/babilon-blanco-simple-new.png"
              alt="logo babilon"
            />
          </div>
        </div>
      </div> */}
    </section>
  );
};

export default Hero;
