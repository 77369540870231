import React, { createContext, useState } from "react";

const headerContext = createContext();

const HeaderProvider = ({ children }) => {
  const [closeMenu, setCloseMenu] = useState(false);

  const datos = {
    setCloseMenu,
    closeMenu,
  };
  return (
    <headerContext.Provider value={datos}>{children}</headerContext.Provider>
  );
};

export { HeaderProvider, headerContext };
