import React from 'react';
import Skeleton from './Skeleton';
// import { BsImage } from "react-icons/bs";
import LoaderLogoBabilon from '../products/loaders/LoaderLogoBabilon/LoaderLogoBabilon';

const SkeletonEvent = () => {
  let text = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  return (
    <div className="skeleton__Event">
      <Skeleton classes="title width-25" />
      <Skeleton classes="imgEvent">
        {/* <BsImage className="skeleton__iconImage" /> */}
        <LoaderLogoBabilon />
      </Skeleton>
      <div className="skeleton__Event-left">
        <Skeleton classes="imgProduct">
          {/* <BsImage className="skeleton__iconImage" /> */}
          <LoaderLogoBabilon />
        </Skeleton>
        <div className="skeleton__Event-left-text">
          {text.map((el, i) => (
            <Skeleton classes="text width-100" key={i} />
          ))}
        </div>
      </div>
      <div className="skeleton__Event-right">
        <Skeleton classes="imgProduct">
          {/* <BsImage className="skeleton__iconImage" /> */}
          <LoaderLogoBabilon />
        </Skeleton>
        <div className="skeleton__Event-left-text">
          {text.map((el, i) => (
            <Skeleton classes="text width-100" key={i} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SkeletonEvent;
