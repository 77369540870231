import { Route, Routes, useParams } from 'react-router-dom';

//Importacion de componentes
import { LangProvider } from './context/langContext';
// import { loginContext } from "./context/loginContext";
// import { useContext } from "react";
import HomePage from './pages/main/HomePage';
import ProductPage from './pages/product/ProductPage';
import ProductsPage from './pages/product/ProductsPage';
import EventsAndNewsPage from './pages/event/EventsAndNewsPage';
import EventPage from './pages/event/EventPage';
import SeriesPage from './pages/product/SeriesPage';
import ErrorPage from './pages/error/ErrorPage';
// import LoginPage from "./pages/login/LoginPage";
import HomeLayout from './layouts/HomeLayout';
import Layout from './layouts/Layout';
// import DashboardLayout from "./layouts/DashboardLayout";
// import DashboardProductsPage from "./pages/user/DashboardProductsPage";
// import DashboardEventsPage from "./pages/user/DashboardEventsPage";
// import DashboardArtistsPage from "./pages/user/DashboardArtistsPage";
// import DashboardSeriesPage from "./pages/user/DashboardSeriesPage";
// import DashboardGuitarsPage from "./pages/user/DashboardGuitarsPage";
// import ProtectedRoute from "./routes/ProtectedRoute";
// import DashboardProductPage from "./pages/user/DashboardProductPage";
// import DashboardProductEditPage from "./pages/user/DashboardProductEditPage";

//Importacion de estilos
import './App.css';
import FamilyPage from './pages/family/FamilyPage';
import AboutPage from './pages/about/AboutPage';
import Rostros89Page from './pages/news/Rostros89Page/Rostros89Page';

function App() {
  // const { isAutenticated, user } = useContext(loginContext);
  // const { user: name } = useParams();

  return (
    <LangProvider>
      <Routes>
        <Route path="/" element={<HomeLayout />}>
          <Route index element={<HomePage />} />
          <Route path="family" element={<FamilyPage />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="products/:category" element={<Layout />}>
            <Route index element={<ProductsPage />} />
            <Route path=":product" element={<Layout />}>
              <Route index element={<ProductsPage />} />
              <Route path=":item" element={<ProductPage />} />
            </Route>
          </Route>
          <Route path="events-and-news" element={<Layout />}>
            <Route index element={<EventsAndNewsPage />} />
            <Route path=":event" element={<EventPage />} />
            <Route path="rostros-89-new" element={<Rostros89Page />} />
          </Route>
          <Route path="Series/:serie" element={<SeriesPage />}>
            <Route index element={<SeriesPage />} />
            <Route path=":category" element={<SeriesPage />} />
          </Route>
        </Route>
        {/* <Route path="/dashboard" element={<DashboardLayout />}>
          <Route
            path=":user"
            element={<ProtectedRoute isAllowed={isAutenticated} />}
          >
            <Route index element={<DashboardProductsPage />} />
            <Route
              path="products"
              element={
                <ProtectedRoute
                  isAllowed={
                    isAutenticated &&
                    (user.roles.includes("editor") ||
                      user.roles.includes("admin"))
                  }
                  redirectTo={`/dashboard/${name}`}
                />
              }
            >
              <Route index element={<DashboardProductsPage />} />
              <Route path=":productId" element={<DashboardProductPage />} />
              <Route
                path="edit/:editId"
                element={<DashboardProductEditPage />}
              />
            </Route>
            <Route path="events" element={<DashboardEventsPage />} />
            <Route
              path="artist"
              element={
                <ProtectedRoute
                  isAllowed={isAutenticated && user.roles.includes("admin")}
                  redirectTo={`/dashboard/${name}`}
                >
                  <DashboardArtistsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="series"
              element={
                <ProtectedRoute
                  isAllowed={isAutenticated && user.roles.includes("admin")}
                  redirectTo={`/dashboard/${name}`}
                >
                  <DashboardSeriesPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="guitars"
              element={
                <ProtectedRoute
                  isAllowed={isAutenticated && user.roles.includes("admin")}
                  redirectTo={`/dashboard/${name}`}
                >
                  <DashboardGuitarsPage />
                </ProtectedRoute>
              }
            />
          </Route>
        </Route>
        <Route path="/login" element={<LoginPage />} /> */}
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </LangProvider>
  );
}

export default App;
