import { IoIosArrowRoundBack } from 'react-icons/io';

// estilos
import styles from './Popup.module.css';

const Popup = ({ children, onClick }) => {
  return (
    <div className={styles.popupContainer}>
      <div className={styles.popupCloseBtnContainer} onClick={onClick}>
        <IoIosArrowRoundBack className={styles.popupCloseBtn} />
      </div>
      <div className={styles.popupContent}>{children}</div>
    </div>
  );
};

export default Popup;
