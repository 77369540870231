import React from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useSlide } from "../../../hooks/useSlide";

import "./slider.css";
import TopProductCard from "../topProductCard/TopProductCard";

const Slider = ({ dataSlide }) => {
  const { currentSlide, nextSlide, prevSlide } = useSlide(dataSlide);
  return (
    <div className="slider">
      <div className="slider__container">
        <div
          className="slider__list"
          style={{
            transform: `translateX(-${currentSlide * 310}px)`,
          }}
        >
          {dataSlide.map((el) => (
            <TopProductCard dataProduct={el} key={el.id} />
          ))}
        </div>
        <div className="slider__arrow-back slider__arrow" onClick={prevSlide}>
          <IoIosArrowBack />
        </div>
        <div className="slider__arrow-next slider__arrow" onClick={nextSlide}>
          <IoIosArrowForward />
        </div>
      </div>
    </div>
  );
};

export default Slider;
