import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import DinamicHero from '../../components/products/dinamicHero/DinamicHero';
import Category from '../../components/products/category/Category';
import StringsCategory from '../../components/products/stringsCategory/StringsCategory';
import { Helmet } from 'react-helmet';
import { langContext } from '../../context/langContext';

const ProductsPage = () => {
  const { category, product } = useParams();
  const { locale } = useContext(langContext);

  const [title, setTitle] = useState();

  const handleTitle = (product) => {
    if (product === 'Guitar') setTitle('Guitarra');
    if (product === 'Bass') setTitle('Bajo');
    if (product === 'Ukulele') setTitle('Ukulele');
  };

  useEffect(() => {
    handleTitle(product);
  }, [product]);

  return (
    <section>
      <Helmet>
        <title>
          {product === undefined ? `${category}` : `${product} - ${category}`}
        </title>
      </Helmet>
      <section>
        <DinamicHero />
        <div className="category__container">
          <div className="title__left title--category">
            <span>
              {locale === 'en-US'
                ? product === 'Acoustic-Electric' || product === 'Acoustic'
                  ? 'Guitars'
                  : category === 'Strings'
                  ? product
                  : product
                : product === 'Acoustic-Electric' || product === 'Acoustic'
                ? 'Guitarras'
                : category === 'Strings'
                ? title
                : product}
            </span>
          </div>
          {category === 'Strings' ? (
            <StringsCategory product={product} lang={locale} />
          ) : (
            <Category />
          )}
        </div>
      </section>
    </section>
  );
};

export default ProductsPage;
