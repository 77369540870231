import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { langContext } from "../../../context/langContext";
import "./event.css";

const Event = ({ infoEvent }) => {
  const { locale } = useContext(langContext);
  const { usLang, esLang, imagesEvent, iconEvent } = infoEvent;
  const lang = locale === "en-US" ? usLang : esLang;
  return (
    <section className="event">
      <Helmet>
        <title>{`Event - ${lang.title}`}</title>
      </Helmet>
      <h3 className="event__title">{lang.title}</h3>
      <div className="event__icon-event-container">
        <img
          loading="lazy"
          src={iconEvent}
          alt="icono evento"
          className="event__icon-event"
        />
      </div>
      <div className="event__section-left">
        <div>
          <p className="event__section-text">{lang.largeInfo[0]}</p>
          <p className="event__section-text">{lang.largeInfo[1]}</p>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <img
            loading="lazy"
            className="event__section-img"
            src={imagesEvent[1]}
            alt="event"
          />
        </div>
      </div>
      <div className="event__img-main">
        <img
          loading="lazy"
          src={imagesEvent[2]}
          className="event__main-i"
          alt="event babilon"
        />
      </div>
    </section>
  );
};

export default Event;
