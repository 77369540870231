import React, { useState, useEffect } from 'react';
import { getArtists } from '../../../services/artists';
import { FormattedMessage } from 'react-intl';
import ArtistCard from '../artistCard/ArtistCard';

import './artist.css';

// import artists from "../../../json-data/artists.json";

const Artist = () => {
  const [artists, setArtists] = useState([]);

  useEffect(() => {
    getArtists().then((data) => {
      setArtists(data);
    });
  }, []);

  return (
    <section className="artist">
      <div className="title-center" style={{ fontWeight: '700' }}>
        <span>
          <FormattedMessage id="artist.title" defaultMessage="Our Artist" />
        </span>
        {/* <div className="title__left-line" style={{ margin: "auto", marginBottom: "50px" }}></div> */}
        <div className="title__left-line"></div>
      </div>
      <div className="artist__list">
        {artists.map((el) => (
          <ArtistCard infoCard={el} key={el._id} />
        ))}
      </div>
    </section>
  );
};

export default Artist;
