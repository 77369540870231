import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getProductsByCategoryAndProduct } from '../../../services/products.js';
import CardProduct from '../cardProduct/CardProduct';
import SkeletonProductCards from '../../skeletons/SkeletonProductCards';

import './category.css';
import { FormattedMessage } from 'react-intl';

const Category = () => {
  const { category, product } = useParams();
  const [products, setProducts] = useState([]);
  const [unique, setUnique] = useState([]);
  const [bSeries, setBSeries] = useState([]);
  const [electroA, setElectroA] = useState([]);
  const [acoustic, setAcoustic] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(true);
    getProductsByCategoryAndProduct(category, product).then((data) => {
      const firstData = data
        .filter((el) => el.serie === 'B-Series')
        .filter((el) => el.isSubModelo !== true);
      const itemThender = firstData.filter((el) => el.modelo !== 'Thunder');
      // const itemShredder = itemThender.filter((el) => el.modelo !== 'Zeluz');
      // const realData = [...itemShredder, itemThender[2]];
      const realData = itemThender;
      setProducts(data);
      setUnique(data.filter((el) => el.serie === 'Unique').reverse());
      setBSeries(
        category !== 'Guitars'
          ? data.filter((el) => el.serie === 'B-Series')
          : realData
      );
      setElectroA(data.filter((el) => el.type === 'acoustic-electric'));
      setAcoustic(data.filter((el) => el.type === 'acoustic'));
      setTimeout(() => {
        setLoader(false);
      }, 1500);
    });
  }, [product, category]);

  return loader ? (
    <SkeletonProductCards />
  ) : (
    <div>
      {product === undefined ? (
        <div>
          <div>
            <h2 className="strings__Title">UNIQUE</h2>
            <div className="categoryStrings__list">
              <div className="categoryStrings__list-ft">
                {unique.map((el, index) => (
                  <CardProduct key={index} prod={el} />
                ))}
              </div>
            </div>
          </div>
          <div>
            <h2 className="strings__Title">B-SERIES</h2>
            <div className="categoryStrings__list">
              <div className="categoryStrings__list-ft">
                {bSeries.map((el, index) => (
                  <CardProduct key={index} prod={el} />
                ))}
              </div>
            </div>
          </div>
          {electroA.length > 0 && (
            <div>
              <h2 className="strings__Title">
                <FormattedMessage
                  id="guitars.card2"
                  defaultMessage="ACOUSTIC-ELECTRIC"
                />
              </h2>
              <div className="categoryStrings__list">
                <div className="categoryStrings__list-ft">
                  {electroA.map((el, index) => (
                    <CardProduct key={index} prod={el} />
                  ))}
                </div>
              </div>
            </div>
          )}
          {acoustic.length > 0 && (
            <div>
              <h2 className="strings__Title">
                <FormattedMessage
                  id="guitars.card3"
                  defaultMessage="ACOUSTIC"
                />
              </h2>
              <div className="categoryStrings__list">
                <div className="categoryStrings__list-ft">
                  {acoustic.map((el, index) => (
                    <CardProduct key={index} prod={el} />
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="category__list">
          <div className="category__list-ft">
            {products.map((el) => (
              <CardProduct key={el._id} prod={el} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Category;
