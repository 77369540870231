import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { MdOutlineLocationOn } from "react-icons/md";
import { MdKeyboardArrowUp } from "react-icons/md";
import { MdKeyboardArrowDown } from "react-icons/md";
import { headerContext } from "../../../context/headerContext";
import { FormattedMessage } from "react-intl";
import { langContext } from "../../../context/langContext";
import { useDropdown } from "../../../hooks/useDropdown";

import "./dropdownMovil.css";

const DropdownMovil = ({ info }) => {
  const { setCloseMenu } = useContext(headerContext);
  const { currentLabel, items, labelUs, content } = useDropdown(info);
  const { locale } = useContext(langContext);
  const [show, setShow] = useState(false);

  const currentLang = locale === "en-US";

  const handleShow = () => {
    setShow(!show);
  };

  const handleClickItem = () => {
    setTimeout(() => {
      setCloseMenu(false);
    }, 500);
  };

  return (
    <div className="dropdownMov">
      {!info.redirectTo ||
      labelUs === "Guitars" ||
      labelUs === "Basses" ||
      labelUs === "Ukuleles" ? (
        <div className="dropdownMov__label" onClick={handleShow}>
          {content &&
            (show ? (
              <div className="label__arrow-up">
                <span className="label__tag">{currentLabel}</span>
                <MdKeyboardArrowUp />
              </div>
            ) : (
              <div className="label__arrow-down">
                <span className="label__tag">{currentLabel}</span>
                <MdKeyboardArrowDown />
              </div>
            ))}
        </div>
      ) : (
        <Link
          to={info.redirectTo}
          className="dropdownMov__label"
          onClick={() => setCloseMenu(false)}
        >
          <span className="label__tag">{currentLabel}</span>
        </Link>
      )}

      {content && (
        <nav className="dropdownMov__nav">
          <div className={`navMov__help ${show && "navMov__show"}`}>
            <div className="navMov__header">
              {/* <h2>{currentLabel}</h2> */}
              {labelUs === "Dealers" && (
                <a
                  href="https://hermesmusicclub.mx/pages/sucursales"
                  target="blank"
                  className="nav__header__pStores"
                >
                  <MdOutlineLocationOn />
                  <FormattedMessage
                    id="drop.phisicalStore"
                    defaultMessage={"Shops"}
                  />
                </a>
              )}
            </div>
            {labelUs === "Dealers" ? (
              <div className="navMov__dealers">
                {items?.map((el, index) => (
                  <a
                    className="dealers__dealer"
                    key={`${index}-deMov`}
                    href={el.url}
                    target="blank"
                  >
                    <img
                      className="dealer__image"
                      src={el.image}
                      alt="brand dealer"
                    />
                    {el.usa && <span className="dealer__usaTag">U.S.A.</span>}
                    <span className="dealer__link">Online</span>
                  </a>
                ))}
              </div>
            ) : (
              <div className="navMov__content">
                <div className="nav__list">
                  {items.map((el, index) => (
                    <Link
                      key={index}
                      className="list__item"
                      to={el.redirecTo}
                      onClick={() => handleClickItem()}
                    >
                      {currentLang ? el.usName : el.esName}
                    </Link>
                  ))}
                </div>
                <div className="nav__wrapper-img">
                  <img
                    className="nav__img"
                    src={items[0].image}
                    alt="producto Babilon"
                  />
                  <div className="nav__opacity-efect-right"></div>
                </div>
              </div>
            )}
          </div>
        </nav>
      )}
    </div>
  );
};

export default DropdownMovil;
