// estilos
import styles from './YouTubeEmbed.module.css';

const YouTubeEmbed = ({ videoId }) => {
  return (
    <>
      <iframe
        className={styles.youTubeVideo}
        title="Demo"
        src={`https://www.youtube.com/embed/${videoId}`}
        allow="autoplay; encrypted-media"
        allowFullScreen
      ></iframe>
    </>
  );
};

export default YouTubeEmbed;
