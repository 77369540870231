// estilos
import styles from './VideoPlayer.module.css';

const VideoPlayer = ({ url }) => {
  return (
    <video controls muted loop autoPlay className={styles.video}>
      <source src={url} type="video/mp4" />
      {/* <source src="ruta-del-video.ogg" type="video/ogg" /> */}
      {/* Mensaje si el navegador no soporta la etiqueta video */}
      Tu navegador no soporta el elemento de video.
    </video>
  );
};

export default VideoPlayer;
