import { Link } from "react-router-dom";
import "./serieCard.css";

const SerieCard = ({ serieInfo }) => {
  const { imgUrl, routeUrl } = serieInfo;

  return (
    <Link
      to={`${routeUrl}`}
      className="serie__card"
      style={{ backgroundImage: `url(${imgUrl})` }}
    ></Link>
  );
};

export default SerieCard;
