import React from "react";
import { Link } from "react-router-dom";
import { useCardProduct } from "../../../hooks/useCardProduct.js";

import "./cardProduct.css";

const CardProduct = ({ prod }) => {
  const {
    category,
    product,
    _id,
    mainImg,
    imgSize,
    imgHeight,
    modelo,
    subCategory,
    nameCatego,
  } = useCardProduct(prod);

  return (
    <Link
      className="cardProduct"
      to={`/products/${category}/${product}/${_id}`}
    >
      <img
        loading="lazy"
        className="cardProduct__img"
        src={mainImg}
        alt="imagen de producto babilon"
        style={{ objectFit: `${imgSize}`, height: `${imgHeight}` }}
      />

      <span className="cardProduct__model">
        {subCategory ? nameCatego : modelo}
      </span>
    </Link>
  );
};

export default CardProduct;
