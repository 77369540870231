import React from "react";
import "./TopProductCard.css";
import { Link } from "react-router-dom";

const TopProductCard = ({ dataProduct }) => {
  const { modelo, topImg, category, product, _id } = dataProduct;
  return (
    <Link
      to={`/products/${category}/${product}/${_id}`}
      className="topProductCard"
    >
      <img src={topImg} alt="product Babilon" className="topProductCard__img" />
      {category !== "strings" && (
        <h3 className="topProductCard__title">{modelo}</h3>
      )}
    </Link>
  );
};

export default TopProductCard;
