const resources = {
  es: {
    translation: {},
  },
  en: {
    translation: {},
  },
};

export default resources;
