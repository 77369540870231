import React from 'react';

import styles from './Rostros89Page.module.css';
import VideoPlayer from '../../../components/video/VideoPlayer';

import { langContext } from '../../../context/langContext';
import { useContext } from 'react';

// contenido
import content from '../../../jsons/news/rostros89.json';

const Rostros89Page = () => {
  const { lang } = useContext(langContext);

  return (
    <section className={styles.pageNewsContainer}>
      <article className={styles.newsHeader}>
        <div className={styles.headerLogo}>
          <img
            src="https://res.cloudinary.com/de17sq7ft/image/upload/v1732735612/logo_qscfr0.png"
            alt="logo"
          />

          <p>By Arturo Ybarra</p>
        </div>
        <img
          className={styles.headerArturo}
          src="https://res.cloudinary.com/de17sq7ft/image/upload/v1731340510/arturo-png_ebedgg.png"
          alt="arturo ybarra"
        />
      </article>

      <article className={styles.newsArticle}>
        <div>
          <div className={styles.videoContainer}>
            <VideoPlayer url={'/video/rostros89-video-1.mp4'} />
          </div>
        </div>
        <div className={styles.articleText}>
          <h1>Rostros 89</h1>
          <p>{content.text01[lang]}</p>
        </div>
      </article>

      <article className={styles.newsArticle}>
        <div className={styles.articleText}>
          <p>{content.text02[lang]}</p>
        </div>
        <div className={styles.img89Pickups}>
          <img
            src="https://res.cloudinary.com/de17sq7ft/image/upload/v1731081777/rostros89-pickups_ib8c6g.jpg"
            alt="rostro 89 pickups"
          />
        </div>
      </article>

      <article className={styles.newsArticle89}>
        <div className={styles.img89}>
          <img
            src="https://res.cloudinary.com/de17sq7ft/image/upload/v1731081777/rostros89-guitar_n32xd4.jpg"
            alt="rostros 89 guitar"
          />
        </div>
        <div className={styles.articleText}>
          <p>{content.text03[lang]}</p>
        </div>
      </article>

      <article className={styles.newsFooter}>
        <div className={styles.footerInfo}>
          <p>{content.text04[lang]}</p>
          <div className={styles.footerGallery}>
            <img
              src="https://res.cloudinary.com/de17sq7ft/image/upload/v1730136129/arturo-gallery-01_kmtnxg.jpg"
              alt="foto galería"
            />
            <img
              src="https://res.cloudinary.com/de17sq7ft/image/upload/v1730737398/arturo-gallery-05_wl57tw.jpg"
              alt="foto galería"
            />
            <img
              src="https://res.cloudinary.com/de17sq7ft/image/upload/v1730737398/arturo-gallery-04_egflj8.jpg"
              alt="foto galería"
            />
          </div>
        </div>
        <p className={styles.footerEnd}>{content.text05[lang]}</p>
      </article>
    </section>
  );
};

export default Rostros89Page;
