import React from "react";
import Artist from "../../components/main/artist/Artist";
import SectionEvents from "../../components/main/sectionEvents/SectionEvents";

const FamilyPage = () => {
  return (
    <section>
      <Artist />
      <SectionEvents />
    </section>
  );
};

export default FamilyPage;
