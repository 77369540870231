import { useState } from "react";

export const useProductImages = (productImgs) => {
  const [loading, setLoading] = useState(false);
  const { colors, rutas, category } = productImgs;
  const allColors = colors || [];
  const [colorImages, setColorImages] = useState(0);
  const [mainIMG, setMainIMG] = useState(0);
  const mainImg = category === "strings" ? rutas : colors[colorImages]?.rutas;

  const handleColorImages = (index) => {
    setLoading(true);
    setColorImages(index);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  };
  const handleMainIMG = (index) => {
    setMainIMG(index);
  };
  return {
    mainImg,
    mainIMG,
    category,
    allColors,
    handleColorImages,
    handleMainIMG,
    loading,
  };
};
