import { useContext } from "react";
import { langContext } from "../context/langContext";

export const useArtistCard = (infoCard) => {
  const { locale } = useContext(langContext);
  const { name, usDescription, esDescription, img, facebook, twitter } =
    infoCard;

  const currentDescription = locale === "en-US" ? usDescription : esDescription;
  return {
    img,
    currentDescription,
    name,
    facebook,
    twitter,
  };
};
