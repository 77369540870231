import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { MdOutlineLocationOn } from "react-icons/md";
import { FormattedMessage } from "react-intl";
import { langContext } from "../../../context/langContext";
import { useDropdown } from "../../../hooks/useDropdown";

import "./dropdown.css";

const Dropdown = ({ info }) => {
  const { currentLabel, items, labelUs, content } = useDropdown(info);
  const { locale } = useContext(langContext);
  const [image, setImage] = useState("");
  const [showImg, setShowImg] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const currentLang = locale === "en-US";

  const hanldeMouseOver = (img) => {
    setImage(img);
    setShowImg(true);
  };
  const hanldeMouseOut = () => {
    setShowImg(false);
  };

  return (
    <div
      className="dropdown"
      onMouseOver={() => setShowMenu(true)}
      onMouseOut={() => setShowMenu(false)}
    >
      {content && (
        <div
          className={`nav__back ${showMenu ? "show-back" : ""}`}
          onMouseOver={() => setShowMenu(false)}
        ></div>
      )}

      {!info.redirectTo ? (
        <div className="dropdown__label">
          <span className="label__text">{currentLabel}</span>
        </div>
      ) : (
        <Link
          to={info.redirectTo}
          className="dropdown__label"
          onClick={() => setShowMenu(false)}
        >
          <span className="label__text">{currentLabel}</span>
        </Link>
      )}

      {content && (
        <nav className={`dropdown__nav ${showMenu ? "show" : ""}`}>
          {!info.redirectTo ? (
            <div className="nav__header">
              <h2>{currentLabel}</h2>
              {labelUs === "Dealers" && (
                <a
                  href="https://hermesmusicclub.mx/pages/sucursales"
                  target="blank"
                  className="nav__header__pStores"
                >
                  <MdOutlineLocationOn />
                  <FormattedMessage
                    id="drop.phisicalStore"
                    defaultMessage={"Shops"}
                  />
                </a>
              )}
            </div>
          ) : (
            <Link
              to={info.redirectTo}
              className="nav__header"
              onClick={() => setShowMenu(false)}
            >
              <h2>{currentLabel}</h2>
            </Link>
          )}

          {labelUs === "Dealers" ? (
            <div className="nav__dealers">
              {items?.map((el, index) => (
                <a
                  className="dealers__dealer"
                  href={el.url}
                  key={`${index}-dealDrop`}
                  target="blank"
                >
                  <img
                    className="dealer__image"
                    src={el.image}
                    alt="brand dealer"
                  />
                  {el.usa && <span className="dealer__usaTag">U.S.A.</span>}
                  <span className="dealer__link">Online</span>
                </a>
              ))}
            </div>
          ) : (
            <div className="nav__content">
              <div className="nav__list">
                {items.map((el, index) => (
                  <Link
                    key={`${index}-drop`}
                    className="list__item"
                    onMouseOver={() => hanldeMouseOver(el.image)}
                    onMouseOut={hanldeMouseOut}
                    onClick={() => setShowMenu(false)}
                    to={el.redirecTo}
                  >
                    {currentLang ? el.usName : el.esName}
                  </Link>
                ))}
              </div>
              <div  className={`nav__img ${showImg && "nav__img-show"}`}>
              <img
                src={image}
                alt="producto Babilon"
              />
              <div className="nav__opacity-efect-right"></div>
              </div>
            </div>
          )}
        </nav>
      )}
    </div>
  );
};

export default Dropdown;
