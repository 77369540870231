import React, { useContext, useState } from 'react';
import { BsFacebook } from 'react-icons/bs';
import { IoLogoWhatsapp } from 'react-icons/io';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { langContext } from '../../../context/langContext';
import ProductDemos from '../ProductDemos/ProductDemos';

import './productInfo.css';

const ProductInfo = ({ productInfo }) => {
  let { pathname } = useLocation();
  const { usLang, esLang, isObjFeats, demos } = productInfo;
  const { locale } = useContext(langContext);
  const currentLang = locale === 'en-US' ? usLang : esLang;
  const media = Math.round(currentLang.featuresText.length / 3);

  const [toggleState, setToggleState] = useState(1);

  const tap1 = currentLang.featuresText.slice(0, media);
  const tap2 = currentLang.featuresText.slice(media, media * 2);
  const tap3 = currentLang.featuresText.slice(
    media * 2,
    currentLang.featuresText.length
  );

  const toggleTab = (index) => {
    setToggleState(index);
  };

  return (
    <div className="productPage__info">
      <div className="productPage__info-description">
        <h3 className="productPage__info-title">
          {currentLang.description_small}
        </h3>
        <p className="productPage__info-text">{currentLang.description}</p>
      </div>
      <div className="productPage__info-features">
        <h3 className="productPage__info-title">
          <FormattedMessage id="product.features" defaultMessage="Features:" />
        </h3>
        {isObjFeats ? (
          <div className="productFeatures__container">
            <div className="productFeatures__taps">
              {Object.keys(currentLang.objFeatures).map((el, index) => (
                <button
                  key={index}
                  className={
                    toggleState === index + 1
                      ? 'pFeat_tabs active-tab'
                      : 'pFeat_tabs'
                  }
                  onClick={() => toggleTab(index + 1)}
                >
                  {el}
                </button>
              ))}
            </div>
            <div className="productFeatures__content">
              {Object.values(currentLang.objFeatures).map((el, index) => (
                <div
                  key={index}
                  className={
                    toggleState === index + 1
                      ? 'pFeat_content  active-content'
                      : 'pFeat_content'
                  }
                >
                  <ul className="productPage__info-text">
                    {el.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="productFeatures__container">
            {/* <ul>
            {currentLang.featuresText.map((el, i) => (
              <li key={i}>{el}</li>
            ))}
          </ul> */}
            <div className="productFeatures__taps">
              <button
                className={
                  toggleState === 1 ? 'pFeat_tabs active-tab' : 'pFeat_tabs'
                }
                onClick={() => toggleTab(1)}
              >
                Tap 1
              </button>
              <button
                className={
                  toggleState === 2 ? 'pFeat_tabs active-tab' : 'pFeat_tabs'
                }
                onClick={() => toggleTab(2)}
              >
                Tap 2
              </button>
              <button
                className={
                  toggleState === 3 ? 'pFeat_tabs active-tab' : 'pFeat_tabs'
                }
                onClick={() => toggleTab(3)}
              >
                Tap 3
              </button>
            </div>
            <div className="productFeatures__content">
              <div
                className={
                  toggleState === 1
                    ? 'pFeat_content  active-content'
                    : 'pFeat_content'
                }
              >
                <ul className="productPage__info-text">
                  {tap1.map((el, index) => (
                    <li key={index}>{el}</li>
                  ))}
                </ul>
              </div>
              <div
                className={
                  toggleState === 2
                    ? 'pFeat_content  active-content'
                    : 'pFeat_content'
                }
              >
                <ul className="productPage__info-text">
                  {tap2.map((el, index) => (
                    <li key={index}>{el}</li>
                  ))}
                </ul>
              </div>
              <div
                className={
                  toggleState === 3
                    ? 'pFeat_content  active-content'
                    : 'pFeat_content'
                }
              >
                <ul className="productPage__info-text">
                  {tap3.map((el, index) => (
                    <li key={index}>{el}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        )}
        {demos && <ProductDemos demos={demos} />}
        <div className="productpage__shares">
          <h3 className="productPage__colors-title">
            <FormattedMessage id="product.share" defaultMessage="Share" />
          </h3>
          <div className="shares">
            <a
              href={`http://www.facebook.com/sharer.php?u=www.babilonguitars.com/#${pathname}`}
              target="blank"
              className="share__btn face"
            >
              <BsFacebook />
            </a>
            <a
              href={`https://api.whatsapp.com/send?text=https://www.babilonguitars.com/#${pathname}`}
              target="blank"
              className="share__btn what"
            >
              <IoLogoWhatsapp />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductInfo;
