import { useState } from 'react';
import YouTubeEmbed from '../YouTubeEmbed/YouTubeEmbed';

// estilos
import styles from './ProductDemos.module.css';

const ProductDemos = ({ demos }) => {
  const [currentVideo, setCurrentVideo] = useState({
    toggle: 'c',
  });

  const handleToggleActive = (event) => {
    const value = event.target.value;

    setCurrentVideo({ toggle: value });
  };

  return (
    <div className={styles.productDemosContiner}>
      <h3 className={styles.productDemosTitle}>Demos:</h3>
      <div className={styles.toggleBar}>
        <button
          value={'c'}
          className={`${styles.toggle} ${
            currentVideo?.toggle === 'c' && styles.toggleActive
          }`}
          onClick={handleToggleActive}
        >
          Clean
        </button>

        <button
          value={'g'}
          className={`${styles.toggle} ${
            currentVideo?.toggle === 'g' && styles.toggleActive
          }`}
          onClick={handleToggleActive}
        >
          Gain
        </button>
      </div>
      <div>
        {currentVideo?.toggle === 'c' && <YouTubeEmbed videoId={demos.clean} />}
        {currentVideo?.toggle === 'g' && <YouTubeEmbed videoId={demos.gain} />}
      </div>
    </div>
  );
};

export default ProductDemos;
