import React from 'react';
import Skeleton from './Skeleton';
// import { BsImage } from "react-icons/bs";
import LoaderLogoBabilon from '../products/loaders/LoaderLogoBabilon/LoaderLogoBabilon';

const SkeletonEventCards = () => {
  let events = [0, 0, 0];
  return (
    <div className="skeleton-eventCard-list">
      {events.map((el, i) => (
        <div className="EandN__card skeleton-eventCard" key={i}>
          <Skeleton classes="imgEvent">
            {/* <BsImage className="skeleton__iconImage" /> */}
            <LoaderLogoBabilon />
          </Skeleton>
          <div className="EandN__card-info">
            <Skeleton classes="title width-50" />
            <Skeleton classes="date width-25" />
            <Skeleton classes="text width-100" />
            <Skeleton classes="text width-100" />
            <Skeleton classes="text width-100" />
          </div>
        </div>
      ))}
    </div>
  );
};

export default SkeletonEventCards;
