import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getEvent } from "../../services/events";
import Event from "../../components/events/event/Event";
import SkeletonEvent from "../../components/skeletons/SkeletonEvent";

const EventPage = () => {
  const { event } = useParams();
  const [eventFetch, setEventFetch] = useState(null);
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    setLoader(true);
    getEvent(event).then((data) => {
      setEventFetch(data);
      setTimeout(() => {
        setLoader(false);
      }, 1500);
    });
  }, [event]);

  if (eventFetch === null) return <div></div>;

  return loader === true ? <SkeletonEvent /> : <Event infoEvent={eventFetch} />;
};

export default EventPage;
