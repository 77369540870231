// estilos
import { Link } from 'react-router-dom';
import styles from './MelissaHero.module.scss';
import { FormattedMessage } from 'react-intl';

const MelissaHero = () => {
  return (
    <div className={styles.slideContainer}>
      <img
        className={styles.melissaImg}
        src="https://res.cloudinary.com/de17sq7ft/image/upload/v1732227635/Melissa_mzwdb8.png"
        alt="foto de melissa"
      />
      <div className={styles.slideLogo}>
        <img
          className={styles.logoUnique}
          src="https://res.cloudinary.com/de17sq7ft/image/upload/v1732737071/unique-bg-black_odxglt.png"
          alt="unique logo"
        />
        <img
          src="https://res.cloudinary.com/de17sq7ft/image/upload/v1732227635/Eureka_Logo_qeb40o.png"
          alt="logo de el modelo"
        />
        <Link
          to="Series/Unique/Ukuleles"
          className={styles.slideBtn}
          style={{
            marginLeft: '10px',
          }}
        >
          <FormattedMessage id="hero.learn" defaultMessage="LEARN MORE" />
        </Link>
      </div>
      <div className={styles.slideProductImg}>
        <img
          className={styles.primaryImg}
          src="https://res.cloudinary.com/de17sq7ft/image/upload/v1732299978/ukulele-01_pnx4mq.png"
          alt="imagen del producto"
        />
        <img
          className={styles.secondaryImg}
          src="https://res.cloudinary.com/de17sq7ft/image/upload/v1732299977/ukulele-02_lcgdft.png"
          alt="imagen del producto"
        />
      </div>
      <span className={styles.heroName}>Melissa Robles</span>
    </div>
  );
};

export default MelissaHero;
