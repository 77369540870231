import axios from "./axios";

export const login = (user) => {
  const url = "auth/signin";
  return axios
    .post(url, user, { withCredentials: true })
    .then((res) => res.data);
};

export const logout = (user) => {
  const url = "auth/logout";
  return axios
    .post(url, user, { withCredentials: true })
    .then((res) => res.data);
};

export const verifyToken = () => {
  const url = "auth/verify";
  return axios.get(url, { withCredentials: true }).then((res) => res.data);
};
