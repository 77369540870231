// import { BsFacebook } from "react-icons/bs";
// import { AiFillInstagram } from "react-icons/ai";
// import { AiFillInstagram, AiFillFacebook } from "react-icons/ai";
import { useArtistCard } from '../../../hooks/useArtistCard';

import './artistCard.css';

const ArtistCard = ({ infoCard }) => {
  const { img, currentDescription, name } = useArtistCard(infoCard);

  return (
    <div className="artistCard__margin">
      <article className="container__artistCard">
        <div class="container__imgCard">
          <img class="card__img" src={img} alt="foto del artista" />
        </div>
        <div class="card__info">
          <p class="card__name">{name}</p>
          <p class="card__description">{currentDescription}</p>
          {/* <div className="card__socials">
            <a href={facebook} className="socials__icon" target="blank">
              <AiFillFacebook />
            </a>
            <a href={twitter} className="socials__icon" target="blank">
              <AiFillInstagram />
            </a>
          </div> */}
        </div>
      </article>
    </div>

    // <article className="artistCard">
    //   <img src={img} alt="artist Babilon" className="artistCard__image" />
    //   <span className="artistCard__title">{name.toUpperCase()}</span>
    //   <p className="artistCard__biografy">{currentDescription}</p>
    //   <div className="artistCard__socials">
    //     <a href={facebook} className="socials__item" target="blank">
    //       <BsFacebook />
    //     </a>
    //     <a href={twitter} className="socials__item" target="blank">
    //       <AiFillInstagram />
    //     </a>
    //   </div>
    // </article>
  );
};

export default ArtistCard;
