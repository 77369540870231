import axios from 'axios';

// develop
// const BASE_URL = 'http://localhost:4000/api/babilon/v1/';

// production
const BASE_URL =
  'https://babilon-back-production.up.railway.app/api/babilon/v1/';

// production anterior
// const BASE_URL =
//   'https://babilon-back-development.up.railway.app/api/babilon/v1/';

export default axios.create({
  baseURL: BASE_URL,
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true,
});
