import { createContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { login, logout, verifyToken } from "../services/auth";
import Cookies from "js-cookie";

const loginContext = createContext();

const LoginProvider = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [isAutenticated, setIsAutenticated] = useState(false);
  const [errors, setErrors] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (errors?.length > 0) {
      const timer = setTimeout(() => {
        setErrors(null);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [errors]);

  const signIn = async (user) => {
    try {
      const res = await login(user);
      if (res) {
        setUser(res);
        setIsAutenticated(true);
        navigate(`/dashboard/${res.username.split(" ")[0]}`);
      }
    } catch (error) {
      console.log(error.response.data);
      setErrors(error.response.data.message);
    }
  };

  const logOut = async (user) => {
    try {
      setUser(null);
      await logout(user);
    } catch (error) {
      console.log(error.response.data);
      setErrors(error.response.data.message);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    const checkLogin = async () => {
      const cookies = Cookies.get();
      if (!cookies.token) {
        setIsAutenticated(false);
        setIsLoading(false);
        return;
      }

      try {
        const res = await verifyToken(cookies.token);
        if (!res) return setIsAutenticated(false);
        setIsAutenticated(true);
        setUser(res);
        navigate(location.pathname);
        setIsLoading(false);
      } catch (error) {
        setIsAutenticated(false);
        setIsLoading(false);
      }
    };
    checkLogin();
    // eslint-disable-next-line
  }, []);

  const datos = {
    setUser,
    user,
    signIn,
    errors,
    isAutenticated,
    setIsAutenticated,
    logOut,
    isLoading,
  };

  return (
    <loginContext.Provider value={datos}>{children}</loginContext.Provider>
  );
};

export { LoginProvider, loginContext };
