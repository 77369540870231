import axios from "./axios";

export const getTopProducts = async () => {
  const url = "products/topProducts/";
  return axios.get(url).then((res) => res.data);
};

export const getProductsByCategoryAndProduct = (category, product) => {
  let url = "";
  if (product === undefined) {
    url = `products?category=${category.toLowerCase()}`;
  } else {
    url = `products?category=${category.toLowerCase()}&type=${product.toLowerCase()}`;
  }
  return axios.get(url).then((res) => res.data);
};

export const getProductsByCategory = (category) => {
  const url = `products?category=${category.toLowerCase()}`;
  return axios.get(url).then((res) => res.data);
};

export const getProductsBySerie = (serie, category) => {
  let url = "";
  if (category === undefined) {
    url = `products?serie=${serie}`;
  } else {
    url = `products?serie=${serie}&category=${category.toLowerCase()}`;
  }
  return axios.get(url).then((res) => res.data);
};

export const getProduct = (item) => {
  const url = `products/${item}`;
  return axios.get(url).then((res) => res.data);
};

export const getProducts = () => {
  const url = "products/";
  return axios.get(url).then((res) => res.data);
};

export const getProductsBySearch = (search) => {
  const url = `products/?q=${search.toLowerCase()}`;
  return axios.get(url).then((res) => res.data);
};
