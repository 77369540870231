import React from 'react';
import Skeleton from './Skeleton';
import LoaderLogoBabilon from '../products/loaders/LoaderLogoBabilon/LoaderLogoBabilon';
// import { BsImage } from "react-icons/bs";

const SkeletonProduct = () => {
  return (
    <div className="skeleton__product">
      <Skeleton classes="heroSKE" />
      <Skeleton classes="title width-25" />
      <div className="">
        <Skeleton classes="imgProduct">
          {/* <BsImage className="skeleton__iconImage" /> */}
          <LoaderLogoBabilon />
        </Skeleton>
        <div className="skeleton__prodcut-Images">
          <Skeleton classes="imgThum">
            {/* <BsImage className="skeleton__iconImage-thum" /> */}
            <LoaderLogoBabilon />
          </Skeleton>
          <Skeleton classes="imgThum">
            {/* <BsImage className="skeleton__iconImage-thum" /> */}
            <LoaderLogoBabilon />
          </Skeleton>
          <Skeleton classes="imgThum">
            {/* <BsImage className="skeleton__iconImage-thum" /> */}
            <LoaderLogoBabilon />
          </Skeleton>
        </div>
      </div>
      <Skeleton classes="title width-50" />
      <Skeleton classes="text width-50" />
      <Skeleton classes="text width-50" />
      <Skeleton classes="text width-50" />
      <Skeleton classes="text width-50" />
      <Skeleton classes="text width-25" />
      <Skeleton classes="text width-25" />
      <Skeleton classes="text width-25" />
      <Skeleton classes="text width-25" />
      <Skeleton classes="text width-25" />
      <Skeleton classes="text width-25" />
    </div>
  );
};

export default SkeletonProduct;
