import React from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import Series from "../../components/products/series/Series";

const SeriesPage = () => {
  const { serie } = useParams();

  return (
    <div>
      <Helmet>
        <title>{serie}</title>
      </Helmet>
      <Series />
    </div>
  );
};

export default SeriesPage;
